import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartComponent } from "ng-apexcharts";
import { EventService } from 'src/app/core/services/event.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { stackBarChart } from '../chart/chartist/data';

@Component({
  selector: 'app-mb-dashboard',
  templateUrl: './mb-dashboard.component.html',
  styleUrls: ['./mb-dashboard.component.scss']
})
export class MBDashboardComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: any;
  public stackBarChart: any;
  public chartOptionsService: any;
  public chartOptionsQR: any;
  data: any
  QRData: any
  packageData: any
  partnerOption = 'MB'
  partnerOptions = [{ value: 'MB', label: 'Đối tác MB' }, { value: 'VT', label: 'Đối tác Viettel' }]

  constructor(
    private eventService: EventService,
    private apiService: ApiService,
    private translate: TranslateService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.getDataDB()
  }

  getDataDB() {
    this.eventService.setLoading(true)
    this.apiService.getList('admin/dashboard-mb-operation?partnerOption=' + this.partnerOption).subscribe({
      next: res => {
        this.data = res.metadata
        console.log(this.data)
        const chartOptionsSeries = [res.metadata.totalDeviceSold, res.metadata.totalDeviceInventory, res.metadata.totalReturnedDevices]
        const stackBarChartData = {
          series: [{
            name: 'Số thiết bị đã bán',
            data: res.metadata.deviceStatsByAgency.map(i => i.total_devices_sold)
          }, {
            name: 'Số thiết bị tồn kho',
            data: res.metadata.deviceStatsByAgency.map(i => i.total_devices - i.total_devices_sold)
          }],
          categories: res.metadata.deviceStatsByAgency.map(i => i.agency_name + `(${i.total_devices_sold}/${i.total_devices})`)
        }
        this.packageData = {
          data: [
            {
              name: "Gói 3M",
              data: res.metadata.revenueLast7dPackage.map(item => item.data_3m)
            },
            {
              name: "Gói 6M",
              data: res.metadata.revenueLast7dPackage.map(item => item.data_6m)
            },
            {
              name: "Gói 12M",
              data: res.metadata.revenueLast7dPackage.map(item => item.data_12m)
            }
          ],
          category: res.metadata.revenueLast7dPackage.map(item =>
            this.datePipe.transform(new Date(item.date), 'dd/MM')
          )
        }
        this.QRData = {
          data: [
            {
              name: "Tổng giá trị giao dịch",
              type: "column",
              data: res.metadata.revenueOR7d.map(item => item.total_amount)
            },
            {
              name: "Tổng số giao dịch",
              type: "line",
              data: res.metadata.revenueOR7d.map(item => item.total_transactions)
            }
          ],
          category: res.metadata.revenueOR7d.map(item =>
            this.datePipe.transform(new Date(item.date), 'dd/MM')
          )
        }
        this.chartOptions = {
          series: chartOptionsSeries,
          chart: {
            width: 380,
            type: "pie"
          },
          labels: ["Thiết bị đã bán", "Thiết bị tồn kho", "Thiết bị trả lại"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        };
        this.stackBarChart = {
          series: stackBarChartData.series,
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            stackType: '100%'
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '50%',
            },
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          title: {
            text: 'Trạng thái từng chi nhánh'
          },
          xaxis: {
            categories: stackBarChartData.categories,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return `${val} thiết bị`;
              }
            }
          },
          fill: {
            opacity: 1

          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          }
        };
        this.chartOptionsService = {
          series: this.packageData.data,
          chart: {
            type: "bar",
            height: 400
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded"
            }
          },
          title: {
            text: "Gói dịch vụ",
            style: {
              fontSize: '14px',
              color: '#606060',
              fontWeight: '600',
              fontFamily: 'open sans, Helvetica, Arial, sans-serif'
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
          },
          xaxis: {
            categories: this.packageData.category,
          },
          yaxis: {
            title: {
              text: "Gói dịch vụ"
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " gói";
              }
            }
          }
        };
        this.chartOptionsQR = {
          series: this.QRData.data,
          chart: {
            height: 400,
            type: "line"
          },
          legend: {
            show: false
          },
          grid: {
            show: false
          },
          stroke: {
            width: [0, 4]
          },
          title: {
            text: "Giao dịch QR",
            style: {
              fontSize: '14px',
              color: '#606060',
              fontWeight: '600',
              fontFamily: 'open sans, Helvetica, Arial, sans-serif'
            }
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1]
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "50%",
              endingShape: "rounded"
            }
          },
          labels: this.QRData.category,
          xaxis: {
            categories: this.QRData.category,
          },
          yaxis: [
            {
              title: {
                text: "Tổng giá trị giao dịch"
              },
              axisBorder: {
                show: true
              },
              axisTicks: {
                show: true
              },
              labels: {
                show: true,
                formatter: function (val) {
                  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " VNĐ";
                }
              }
            },
            {
              opposite: true,
              title: {
                text: "Tổng số giao dịch"
              }
            }
          ]
        };
        this.eventService.setLoading(false)
      },
      error: e => {
        this.eventService.setLoading(false)
        this.apiService.showToast(this.translate.instant('Lỗi dữ liệu')
          , this.translate.instant('Dữ liệu chưa được tải về'), 'error')
      }
    })
  }

}
